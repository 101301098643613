.logo {
  display: flex;
  align-items: center;

  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 2px;
  padding-bottom: 5px;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;

  & i {
    font-size: 2.2rem;
    margin-right: 0.5rem;
  }

  & b {
    font-weight: 500;
  }
}

.logo.dark {
  color: #fff;
}
.logo.light {
  color: #000;
}

@media (max-width: 992px) {
  .logo {
    font-size: 1.2rem;
  }
}