.pagination {
  display: flex;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-content: space-between;
  align-items: center;

  & button {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    background: none;
    cursor: pointer;
  }

  & ul {
    display: flex;
  }

  & li {
    margin: 0 0.5rem;
    padding: 5px 8px;
  }
}

.active {
  border: 1px solid;
}

.disabled {
  opacity: 0.3;
  cursor: default;
}

@media (max-width: 576px) {
  .pagination {
    margin-top: 15px;
  }
}
