.video {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #666;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(40, 40, 50, 0.8);
  }

  & video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.intro {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &>div {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    width: 100%;

    &>span {
      color: #fff;
      font-size: 8rem;
      margin: 0 1.5rem;
      font-weight: 200;
    }
  }

  & button {
    position: relative;
    width: 13rem;
    height: 13rem;
    border-radius: 50%;
    border: 3px solid;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;

    &:hover {
      border-color: #fff !important;

      & .bar {
        background-color: #fff !important;
      }
    }

    & .icon_wrapper {
      position: relative;
      transform: translate(-2rem, -2.6rem);
    }

    &.pause {
      & .bar_1 {
        transform: translateX(11px) translateY(0px) rotate(0deg);
      }

      & .bar_2 {
        transform: translateX(26px) translateY(0px) rotate(0deg);
      }
    }

    &.play {
      & .bar_1 {
        transform: translateX(20px) translateY(-8px) rotate(-55deg);
      }

      & .bar_2 {
        transform: translateX(20px) translateY(8px) rotate(-125deg);
      }
    }
  }

  .bar {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
    width: 3px;
    height: 30px;
    border-radius: 3px;
    transform-origin: center;
    transition: transform 0.4s ease, background 0.5s ease;
  }
}

@media (max-width: 768px) {
  .intro>div {
    flex-wrap: wrap;
  }
}

@media (max-width: 576px) {
  .intro>div>span {
    font-size: 5rem;
  }

  .intro button {
    width: 9rem;
    height: 9rem;
  }
}