.pricing {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 4px solid #000;
  width: 100%;

  & .price {
    width: 100%;
    text-align: center;

    & h4 {
      font-size: 1.6rem;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: center;
      margin-top: 3rem;
    }

    & span {
      font-size: 6rem;
      line-height: 1.3;
    }

    & p {
      font-size: 1.2rem;
    }
  }

  // & .info {
  //   width: 3rem;
  //   height: 3rem;
  //   border-radius: 50%;
  //   background: #fff;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 1.4rem;
  //   margin: 3rem 0;
  //   cursor: pointer;
  // }

  & .info {
    padding: 2rem;
  }

  & li {
    font-size: 1.2rem;

    & i {
      margin-right: 1rem;
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

.footer {
  text-align: center;
  margin-top: 4rem !important;

  & p {
    max-width: 60rem;
    margin: 0 auto;
  }
}

@media (max-width: 576px) {
  .footer {
    margin-top: 2rem !important;
  }
}