.card {
  background: #fff;
  display: flex;
}

.card.grid {
  flex-direction: column;

  & .wrapper {
    height: 30rem;
    flex-shrink: 0;
  }

  & .photo {
    height: 100%;
  }
}

.card.list {
  flex-direction: row;

  & .wrapper {
    width: 40%;
    min-height: 25rem;
    flex-shrink: 0;
  }

  & .photo {
    height: 100%;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #252525;

  & figure {
    position: absolute;
    width: 150px;
    height: 6px;
    bottom: 0;
    right: 0;
    display: block;
    transition: all ease 0.5s;
    font-weight: 500;
  }

  & figcaption {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 6px 14px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
  }
}

.photo {
  transition: all ease 1s;
}

.card:hover .wrapper figure {
  width: 100%;
}

.card:hover .photo {
  transform: scale(1.2);
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .photo {
    height: 20rem;
  }
}

@media (max-width: 768px) {
  .card.list {
    flex-direction: column;

    & .wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 576px) {
  .photo {
    height: 20rem;
  }
}
