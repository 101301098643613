.wrapper {
  display: none;

  &.show {
    display: block;
  }
}

.modal {
  position: relative;
  width: 70%;
  max-width: 60rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  &>i {
    position: absolute;
    top: 2rem;
    right: 2rem;
    color: #000;
    font-size: 2rem;
    z-index: 1000;
    cursor: pointer;
  }
}

@media (max-width: 576px) {
  .modal {
    width: 90%;
  }
}