.featured {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 5rem;
  margin-bottom: 5rem;

  & .wrapper {
    border: 1px solid rgb(240, 240, 240);
    padding: 2rem;
  }

  & h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    align-self: flex-start;
    margin-bottom: 0;
    padding: 0.6rem 1.4rem;
    letter-spacing: 0;
  }
}

.post {
  display: flex;
  align-items: center;

  & figure {
    width: 6.5rem;
    height: 6.5rem;
    flex-shrink: 0;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  font-weight: 600;
  line-height: 1.2;
  width: 100%;

  & a {
    color: #000;
  }

  & .date {
    color: #ccc;
    display: flex;
    align-items: center;
    margin-top: 1rem;

    &:before {
      content: "\f1da";
      font-family: "Line Awesome Free";
      font-weight: 700;
      margin-right: 5px;
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 576px) {
  .featured {
    margin-top: 3rem;
  }
}
